html{
  font-family: 'Poppins', sans-serif!important;
  --defaultColorOne:#47A2B6;
  --defaultColorTwo:#052657;
  --defaultGradientColor: linear-gradient(to left, var(--defaultColorOne), var(--defaultColorTwo));
}

.loading{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background-image: var(--defaultGradientColor); */
  color: #fff;
}
.loading >img{
  height: 200px;
  width: 200px;
}
.themeColor{
  color: var(--defaultColorOne);
  font-weight: bold;
}

.ant-form-item{
  margin-bottom: 12px!important;
}

